import React from "react";

import robotHandImg from "../images/possessed-photography-jIBMSMs4_kA-unsplash_small.jpg"

function AboutPage() {
  return (
    <section className="grid grid-cols-1 md:grid-cols-2 max-w-4x1 mx-auto h-full w-4/6">
      <div className="m-auto">
        <p className="pl-4 leading-loose text-center">
          By Robotic Pty Ltd is an Australian robotics company. All our products are made in Australia, which means we also make our own components.
        </p>
      </div>

      <figure className="m-auto w-4/6">
        <img alt="Robot Hand" src={robotHandImg} className="rounded-md " />
        <figcaption className="font-thin text-xs text-right pr-2">
          Photo by <a href="https://unsplash.com/@possessedphotography?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Possessed Photography</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
        </figcaption>
      </figure>
    </section>
  );
}

export default AboutPage;
